* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --box-shadow: 0 0 3px rgb(60 72 88 / 15%);
}
.mycontainer,
.largeContainer {
  max-width: 1080px;
  width: 100%;
  padding: 0 0.725rem;
  margin-left: auto;
  margin-right: auto;
}
.largeContainer {
  max-width: 1800px;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.navpad {
  padding-top: 92px;
}
